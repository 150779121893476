<template>
  <div class="nk-content nk-content-fluid">
    <div class="container-xl wide-xl">
      <div class="nk-content-inner">
        <div class="nk-content-body">
          <div class="nk-block-head nk-block-head-sm">
            <div class="nk-block-between g-3">
              <div class="nk-block-head-content">
                <h3 class="nk-block-title page-title">
                  Empleados /
                  <strong class="text-primary small"
                    >{{ model.nombre }} {{ model.apellido_paterno }} {{ model.apellido_materno }}</strong
                  >
                </h3>
              </div>
            </div>
          </div>
          <!-- .nk-block-head -->
          <div class="nk-block">
            <div class="card card-bordered">
              <div class="card-aside-wrap">
                <div class="card-content">
                  <form>
                    <div class="card-inner">
                      <div class="nk-block">
                        <div class="nk-block-head">
                          <h5 class="title">Información Personal</h5>
                          <p>
                            Datos básicos como nombre, correo electrónico, etc.
                          </p>
                        </div>
                        <div class="profile-ud-list">
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Nombre
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="model.nombre"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Apellido paterno
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="model.apellido_paterno"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Apellido materno
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="model.apellido_materno"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label">Teléfono</span>
                              <div class="profile-ud-value">
                                <span class="w-100">
                                  <input
                                    type="tel"
                                    class="form-control"
                                    v-model="model.telefono"
                                    disabled
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Género
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <select
                                  class="form-control"
                                  v-model="model.genero"
                                  disabled
                                >
                                  <option
                                    v-for="opt in [
                                      'Masculino',
                                      'Femenino',
                                      'Otro',
                                    ]"
                                    :key="opt"
                                    :value="opt"
                                  >
                                    {{ opt }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Estado Civil
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <select
                                  class="form-control"
                                  v-model="model.estado_civil"
                                  disabled
                                >
                                  <option
                                    v-for="opt in ESTADOS_CIVILES"
                                    :key="opt.key"
                                    :value="opt.value"
                                  >
                                    {{ opt.value }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                ><span
                                  >Fecha de Nacimiento
                                  <span class="ml-1 text-danger">*</span></span
                                ></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="date"
                                  class="form-control"
                                  v-model="model.fecha_nacimiento"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Estado
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="model.estado_nacimiento"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Municipio
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="model.municipio_nacimiento"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Servicio
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="model.servicio"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Puesto
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="model.puesto"
                                  maxlength="500"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Fecha de Ingreso
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="date"
                                  class="form-control"
                                  v-model="model.fecha_ingreso"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Superior
                                <span class="ml-1 text-danger">*</span></span
                              >
                              <div class="profile-ud-value">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="model.superior"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Contacto de Emergencia</span
                              >
                              <div class="profile-ud-value">
                                <textarea
                                  v-model="model.contacto_emergencia"
                                  class="form-control"
                                  maxlength="500"
                                  disabled
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Observaciones</span
                              >
                              <div class="profile-ud-value">
                                <textarea
                                  v-model="model.observaciones"
                                  class="form-control"
                                  maxlength="5000"
                                  disabled
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="profile-ud-item" v-if="isAdmin">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Observaciones Internas</span
                              >
                              <div class="profile-ud-value">
                                <textarea
                                  v-model="model.observaciones_internas"
                                  class="form-control"
                                  maxlength="5000"
                                  disabled
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- .nk-block -->
                      <div class="nk-block">
                        <div class="nk-block-head nk-block-head-line">
                          <h6 class="title overline-title text-base">
                            Información Adicional
                          </h6>
                        </div>
                        <!-- .nk-block-head -->
                        <div class="profile-ud-list">
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label">ID</span>
                              <span class="profile-ud-value">{{
                                model.id_empleado
                              }}</span>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label">Estatus</span>
                              <span class="profile-ud-value">
                                <span :class="['estatus', model.estatus]">{{
                                  model.estatus
                                }}</span>
                              </span>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >{{ model.historial.estatus }} por</span
                              >
                              <span class="profile-ud-value">{{
                                model.historial.responsable
                              }}</span>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label">Fecha</span>
                              <span class="profile-ud-value">{{
                                $filters.fecha(model.historial.fecha)
                              }}</span>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label"
                                >Actualizado por</span
                              >
                              <span class="profile-ud-value">{{
                                model.creador
                              }}</span>
                            </div>
                          </div>
                          <div class="profile-ud-item">
                            <div class="profile-ud wider">
                              <span class="profile-ud-label">Fecha</span>
                              <span class="profile-ud-value">{{
                                $filters.fecha(model.fecha_actualizacion)
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- .profile-ud-list -->
                      </div>
                      <!-- .nk-block -->
                    </div>
                    <!-- .card-inner -->
                  </form>
                </div>
                <!-- .card-content -->
              </div>
              <!-- .card-aside-wrap -->
            </div>
            <!-- .card -->
          </div>
          <!-- .nk-block -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useApi } from "@/use/useApi";
import { useRoute } from "vue-router";
import { ref, onMounted, defineComponent } from "vue";
import Swal from "sweetalert2";
import { ESTADOS_CIVILES } from "@/providers/config";
export default defineComponent({
  name: "Mostrar Empleado",
  components: {},
  setup() {
    //Data
    const { loading, make } = useApi();
    const { params } = useRoute();

    const model = ref({
      curp: "",
      id_empleado: 0,
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      telefono: "",
      genero: "Masculino",
      estado_civil: "Soltero",
      fecha_nacimiento: "",
      id_estado: "01",
      id_municipio: "001",
      puesto: "",
      fecha_ingreso: "",
      observaciones: "",
      observaciones_internas: "",
      historial: {
        estatus: "",
        responsable: "",
      },
    });

    //Methods
    async function get() {
      loading.message = "Obteniendo información";
      let { data } = await make("public/get-info", {
        id_empleado: params.id_empleado,
      });
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          model.value = data.model;
        }
      }
    }

    //Mounted
    onMounted(() => {
      get();
    });

    return {
      model,
      loading,
      ESTADOS_CIVILES,
    };
  },
});
</script>

<style scoped>
.profile-ud-list {
  max-width: inherit;
}
</style>